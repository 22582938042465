import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Product from "../components/Product/Product";


export default function ProductPage(){

    return(
        <Fragment>
          <TopNavigation/>
            <Product/>
        </Fragment>
    )
}
