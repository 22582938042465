import {HashRouter, NavLink, Route, Routes} from "react-router-dom";
import HomePage from "./Pages/HomePage";
import ProductPage from "./Pages/ProductPage";
import ContactPage from "./Pages/ContactPage";
import ClientPage from "./Pages/ClientPage";
import AboutPage from "./Pages/AboutPage";

function App() {

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<HomePage />}/>
                <Route path="/product" element={<ProductPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/client" element={<ClientPage />} />
                <Route path="/about" element={<AboutPage />} />
            </Routes>
        </HashRouter>

    );
}

export default App;
