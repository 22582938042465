import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Contact from "../components/Contact/Contact";


export default function ContactPage(){

    return(
        <Fragment>
        <TopNavigation/>
            <Contact/>
        </Fragment>
    )
}
