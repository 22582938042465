import {Fragment} from "react";
import {Button, Card, Container, Row} from "react-bootstrap";
import img from "../../asset/image/mosquito-coil.avif"
import detergent from "../../asset/image/Detergent.jpeg"

export default function Product(){


    return(
      <Fragment>
          <Container>
              <Row>
                  <Card style={{ width: '18rem' }}>
                      <Card.Img variant="top" src={img} />
                      <Card.Body>
                          <Card.Title>Farco</Card.Title>
                          <Card.Text>
                              Quick Mosquito Killer
                          </Card.Text>
                          <Button variant="primary">Read More</Button>
                      </Card.Body>
                  </Card><Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src={img} />
                  <Card.Body>
                      <Card.Title>Farco</Card.Title>
                      <Card.Text>
                          Quick Mosquito Killer
                      </Card.Text>
                      <Button variant="primary">Read More</Button>
                  </Card.Body>
              </Card><Card style={{ width: '18rem' }}>
                  <Card.Img variant="top" src={img} />
                  <Card.Body>
                      <Card.Title>Beema</Card.Title>
                      <Card.Text>
                          Quick Mosquito Killer
                      </Card.Text>
                      <Button variant="primary">Read More</Button>
                  </Card.Body>
              </Card>
                  <Card style={{ width: '18rem' }}>
                      <Card.Img variant="top" src={img} />
                      <Card.Body>
                          <Card.Title>Beema+</Card.Title>
                          <Card.Text>
                              Quick Mosquito Killer
                          </Card.Text>
                          <Button variant="primary">Go somewhere</Button>
                      </Card.Body>
                  </Card>
                  <Card style={{ width: '18rem' }}>
                      <Card.Img variant="top" src={detergent} />
                      <Card.Body>
                          <Card.Title>Farco detergent</Card.Title>
                          <Card.Text>
                              Qucick wash
                          </Card.Text>
                          <Button variant="primary">Read More</Button>
                      </Card.Body>
                  </Card>
              </Row>
          </Container>
      </Fragment>

    );
}
