import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";


export default function ClientPage(){

    return(
        <Fragment>
            <TopNavigation/>
        </Fragment>
    )
}
