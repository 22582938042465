import {Col, Container, Row} from "react-bootstrap";

export default function Contact(){

    return(

       <Container className="bg-info mt-1">
           <Row>
               <Col>
                   <h1>Corporate Office:</h1>
                   <h3>Suvastu Tower</h3>
                   <h3>Panthapath</h3>
                   <h3>Dhaka</h3>
                   <h3>Telephone</h3>
                   <h3>02-9641273</h3>

               </Col>
               <Col>
                   <h1>Factory:</h1>
                   <h3>Digirhat</h3>
                   <h3>Gobindaganj</h3>
                   <h3>Gaibandha</h3>
                   <h3>Mobile</h3>
                   <h3>+8801738735422</h3>

               </Col>
           </Row>
       </Container>
            );
}
