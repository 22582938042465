import {Fragment} from "react";
import {Container, Row} from "react-bootstrap";


export default function About(){

    return(
        <Fragment>
            <Container className=" text-success mt-1 fs-4 ">
                <Row>
                    <label>
                        The history of  FARCO SYNDICATE can be
                        stretched back to early Eighties. A
                        visionary and dynamic individual with
                        his capable hand started the venture.
                        Throughout several years, by delivering e
                        xcellence to the stakeholders, the group has now become one of
                        the most diversified business conglomerates of the country.
                        The Group has ingrained footsteps in Clearing and Forwarding Agent, Farco Cargo Services Ltd, Farco Shiping Lines . Farco Chemical, Farco Tobacco, Farco Farms. A capable management team is continuously driving excellence and creating ideas to generate values.
                    </label>
                </Row>
            </Container>
        </Fragment>
    );
}
