
import Carousel from 'react-bootstrap/Carousel';
import firstSlide from '../../asset/image/skf.jpg';
import secondSlide from '../../asset/image/Detergent.jpeg';
import thirdSlide from '../../asset/image/CoilPicture.jpeg';

function Slider() {
    return (
        <div className="">
        <Carousel className="slider">
            <Carousel.Item interval={4000}>
                <img src={firstSlide} text="test" />
                <Carousel.Caption>

                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={4000}>
                <img src={secondSlide} text="test" />
                <Carousel.Caption>

                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
                <img src={thirdSlide} text="test" />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item>

        </Carousel>
        </div>
    );
}

export default Slider;
