import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Slider from "../components/Home/Slider";
import About from "../components/About/About";
import Footer from "../components/Home/Footer";


export default function HomePage (){




    return (

        <div className="">
        <TopNavigation/>


            <Slider/>
            <About/>
            <Footer/>
        </div>
    );
}
