import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import About from "../components/About/About";


export default function AboutPage(){


    return(
        <Fragment>
       <TopNavigation/>
            <About/>
        </Fragment>
    )
}
