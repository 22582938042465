import {Fragment, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, NavLink, Route} from "react-router-dom";

export default function TopNavigation(){





    return(

        <Fragment  fluid="true">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="#/">Farco Chemical</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link > <NavLink to="/">Home</NavLink> </Nav.Link>
                                    <Nav.Link ><NavLink to="/product">Products</NavLink></Nav.Link>

                                    <Nav.Link ><NavLink to="/contact">Contacts</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/about">About us</NavLink></Nav.Link>




                        </Nav>

                    </Navbar.Collapse>

                </Container>


            </Navbar>

        </Fragment>
    );
}
