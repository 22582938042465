import {Fragment} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";

export default function Footer(){

    return(

        <Fragment>
            <Container className="bg-dark mb-1 mt-3 text-white text-center">
                <Row>
                    <Col>
                        <div>
                            <h3>Powered By | Farco Chemical</h3>
                        </div>
                    </Col>
                </Row>
            </Container>

        </Fragment>

    )
}
